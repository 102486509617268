const testimonydata =[
    {
        "id":"1",
        "testimony":"The teachers pays attention to the student and have their interest in their heart. They always undergo training at the end of the session",
        "name":"M.O Olawale"
    },
    {
        "id":"2",
        "testimony":"The school ensure health safety. They give first aid treatment to student that are sick",
        "name":"M.K Atanda"
    },
    {
        "id":"3",
        "testimony":"The school believe that education doesn't ends in class alone. They have some extra curricular activity",
        "name":"Temitope Atanda"
    },
    {
        "id":"4",
        "testimony":"Thanks for the moral lesson given to my child. My child has become better in his academmy",
        "name":"Surulere Olorunsogo"
    },
    {
        "id":"5",
        "testimony":"I appreaciate the effort the school is putting into the children to make them become a better person for the society",
        "name":"Jejelaye Buhari"
    },

]
export default testimonydata