import React from 'react';


const Formsuccess = () => {
  return (
    <div className='formsuccess'>
        <div className='container'>
            <h5>Thank you for contacting us. We will respond to your message and get back to you soon</h5>
            
        </div>
        
    </div>
  )
}

export default Formsuccess